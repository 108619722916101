<template>
  <div>
    <BCard>
      <IAmOverlay :loading="!airlines1gCanPayList">
        <BTable
          :items="airlines1gCanPayList"
          :sticky-header="true"
          class="position-relative"
          responsive
          hover
          bordered
          :fields="tableColumns"
          primary-key="airline"
          show-empty
          :empty-text="$t('noMatchingResult')"
          no-border-collapse
          style="max-height: calc(100vh - 200px)"
        >
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]=""
          >
            <span
              :key="column.key"
              class="text-dark text-nowrap"
            >
              {{ $t(`document.airlines1gCanPay.columns.${column.key}`) }}
            </span>
          </template>
          <template #cell(no)="data">
            <div>
              {{ data.index + 1 }}
            </div>
          </template>
          <template #cell(airline)="data">
            <div class="font-weight-bold d-flex-center justify-content-around">
              <b>{{ data.item.carrier }}</b>
              <BBadge
                v-if="data.item.com[0].status==='ENABLED'"
                variant="success"
                pill
                class="badge-glow"
              >
                Được xuất
              </BBadge>
              <BBadge
                v-else
                variant="danger"
                pill
                class="badge-glow"
              >
                Không được xuất
              </BBadge>
            </div>
          </template>
          <template #cell(adt)="data">
            <div
              v-for="(item, index) in data.item.com.filter(i => i.passengerType==='ADULT')"
              :key="`${item.airline}-adt-${index}`"
            >
              <span class="font-weight-bold text-danger">{{ item.value }} %</span>
              <small
                v-if="item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL'"
              >
                (
                <span v-if="(item.includingOrigin && item.includingOrigin !== 'ALL')|| (item.excludingOrigin && item.excludingOrigin !== 'ALL')">{{ $t('from') }} {{ `${item.excludingOrigin ? '!' : ''}${item.includingOrigin || item.excludingOrigin}` }}</span>
                <span v-if="(item.includingDestination && item.includingDestination !== 'ALL')|| (item.excludingDestination && item.excludingDestination !== 'ALL')"> {{ $t('to') }} {{ `${item.excludingDestination ? '!' : ''}${item.includingDestination || item.excludingDestination}` }}</span>
                )
              </small>
            </div>
          </template>
          <template #cell(chd)="data">
            <div
              v-for="(item, index) in data.item.com.filter(i => i.passengerType==='CHILD')"
              :key="`${item.airline}-chd-${index}`"
            >
              <span class="font-weight-bold text-danger">{{ item.value }} %</span>
              <small
                v-if="item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL'"
              >
                (
                <span v-if="(item.includingOrigin && item.includingOrigin !== 'ALL')|| (item.excludingOrigin && item.excludingOrigin !== 'ALL')">{{ $t('from') }} {{ `${item.excludingOrigin ? '!' : ''}${item.includingOrigin || item.excludingOrigin}` }}</span>
                <span v-if="(item.includingDestination && item.includingDestination !== 'ALL')|| (item.excludingDestination && item.excludingDestination !== 'ALL')"> {{ $t('to') }} {{ `${item.excludingDestination ? '!' : ''}${item.includingDestination || item.excludingDestination}` }}</span>
                )
              </small>
            </div>
          </template>
          <template #cell(inf)="data">
            <div
              v-for="(item, index) in data.item.com.filter(i => i.passengerType==='INFANT')"
              :key="`${item.airline}-inf-${index}`"
            >
              <span class="font-weight-bold text-danger">{{ item.value }} %</span>
              <small
                v-if="item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL'"
              >
                (
                <span v-if="(item.includingOrigin && item.includingOrigin !== 'ALL')|| (item.excludingOrigin && item.excludingOrigin !== 'ALL')">{{ $t('from') }} {{ `${item.excludingOrigin ? '!' : ''}${item.includingOrigin || item.excludingOrigin}` }}</span>
                <span v-if="(item.includingDestination && item.includingDestination !== 'ALL')|| (item.excludingDestination && item.excludingDestination !== 'ALL')"> {{ $t('to') }} {{ `${item.excludingDestination ? '!' : ''}${item.includingDestination || item.excludingDestination}` }}</span>
                )
              </small>
            </div>
          </template>
          <template #cell(void)>
            <BBadge variant="dark">
              Không void
            </BBadge>
          </template>
        </BTable>
      </IAmOverlay>
    </BCard>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'

import IAmOverlay from '@/components/IAmOverlay.vue'
import { apiReservation } from '@/api'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    IAmOverlay,
  },
  setup() {
    const tableColumns = [
      { key: 'no' },
      { key: 'airline' },
      { key: 'adt' },
      { key: 'chd' },
      { key: 'inf' },
      { key: 'void' },
      { key: 'note' }]
    const airlines1gCanPayList = ref()
    onMounted(async () => {
      const res = await apiReservation.getCommissions({ size: 1000, sortBy: 'createdAt', sortDirection: 'DESC' })
      const commissionList = res?.data?.items.reverse()
      const mappingList = []
      commissionList.forEach(item => {
        const indexCarrier = mappingList.findIndex(i => i.carrier === item.carrier)
        if (indexCarrier > -1) {
          mappingList[indexCarrier].com.push(item)
        } else {
          mappingList.push({
            carrier: item.carrier,
            com: [item],
          })
        }
      })
      airlines1gCanPayList.value = mappingList
    })
    return {
      airlines1gCanPayList,
      tableColumns,
    }
  },
}
</script>

<style lang="">

</style>
