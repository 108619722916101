var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.airlines1gCanPayList
    }
  }, [_c('BTable', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 200px)"
    },
    attrs: {
      "items": _vm.airlines1gCanPayList,
      "sticky-header": true,
      "responsive": "",
      "hover": "",
      "bordered": "",
      "fields": _vm.tableColumns,
      "primary-key": "airline",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("document.airlines1gCanPay.columns.".concat(column.key))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(airline)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "font-weight-bold d-flex-center justify-content-around"
        }, [_c('b', [_vm._v(_vm._s(data.item.carrier))]), data.item.com[0].status === 'ENABLED' ? _c('BBadge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "success",
            "pill": ""
          }
        }, [_vm._v(" Được xuất ")]) : _c('BBadge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "danger",
            "pill": ""
          }
        }, [_vm._v(" Không được xuất ")])], 1)];
      }
    }, {
      key: "cell(adt)",
      fn: function fn(data) {
        return _vm._l(data.item.com.filter(function (i) {
          return i.passengerType === 'ADULT';
        }), function (item, index) {
          return _c('div', {
            key: "".concat(item.airline, "-adt-").concat(index)
          }, [_c('span', {
            staticClass: "font-weight-bold text-danger"
          }, [_vm._v(_vm._s(item.value) + " %")]), item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL' ? _c('small', [_vm._v(" ( "), item.includingOrigin && item.includingOrigin !== 'ALL' || item.excludingOrigin && item.excludingOrigin !== 'ALL' ? _c('span', [_vm._v(_vm._s(_vm.$t('from')) + " " + _vm._s("".concat(item.excludingOrigin ? '!' : '').concat(item.includingOrigin || item.excludingOrigin)))]) : _vm._e(), item.includingDestination && item.includingDestination !== 'ALL' || item.excludingDestination && item.excludingDestination !== 'ALL' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('to')) + " " + _vm._s("".concat(item.excludingDestination ? '!' : '').concat(item.includingDestination || item.excludingDestination)))]) : _vm._e(), _vm._v(" ) ")]) : _vm._e()]);
        });
      }
    }, {
      key: "cell(chd)",
      fn: function fn(data) {
        return _vm._l(data.item.com.filter(function (i) {
          return i.passengerType === 'CHILD';
        }), function (item, index) {
          return _c('div', {
            key: "".concat(item.airline, "-chd-").concat(index)
          }, [_c('span', {
            staticClass: "font-weight-bold text-danger"
          }, [_vm._v(_vm._s(item.value) + " %")]), item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL' ? _c('small', [_vm._v(" ( "), item.includingOrigin && item.includingOrigin !== 'ALL' || item.excludingOrigin && item.excludingOrigin !== 'ALL' ? _c('span', [_vm._v(_vm._s(_vm.$t('from')) + " " + _vm._s("".concat(item.excludingOrigin ? '!' : '').concat(item.includingOrigin || item.excludingOrigin)))]) : _vm._e(), item.includingDestination && item.includingDestination !== 'ALL' || item.excludingDestination && item.excludingDestination !== 'ALL' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('to')) + " " + _vm._s("".concat(item.excludingDestination ? '!' : '').concat(item.includingDestination || item.excludingDestination)))]) : _vm._e(), _vm._v(" ) ")]) : _vm._e()]);
        });
      }
    }, {
      key: "cell(inf)",
      fn: function fn(data) {
        return _vm._l(data.item.com.filter(function (i) {
          return i.passengerType === 'INFANT';
        }), function (item, index) {
          return _c('div', {
            key: "".concat(item.airline, "-inf-").concat(index)
          }, [_c('span', {
            staticClass: "font-weight-bold text-danger"
          }, [_vm._v(_vm._s(item.value) + " %")]), item.includingOrigin !== 'ALL' && item.includingDestination !== 'ALL' ? _c('small', [_vm._v(" ( "), item.includingOrigin && item.includingOrigin !== 'ALL' || item.excludingOrigin && item.excludingOrigin !== 'ALL' ? _c('span', [_vm._v(_vm._s(_vm.$t('from')) + " " + _vm._s("".concat(item.excludingOrigin ? '!' : '').concat(item.includingOrigin || item.excludingOrigin)))]) : _vm._e(), item.includingDestination && item.includingDestination !== 'ALL' || item.excludingDestination && item.excludingDestination !== 'ALL' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('to')) + " " + _vm._s("".concat(item.excludingDestination ? '!' : '').concat(item.includingDestination || item.excludingDestination)))]) : _vm._e(), _vm._v(" ) ")]) : _vm._e()]);
        });
      }
    }, {
      key: "cell(void)",
      fn: function fn() {
        return [_c('BBadge', {
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v(" Không void ")])];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }